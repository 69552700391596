.container {
}

.meter {
  display: block;
  height: 4px;
  border-radius: 1px;
  //outline: 1px solid silver;
  width: 10%;
  margin-bottom: 10px;

  &.tooWeak {
    width: 30%;
    //outline: 1px solid red;
    background-color: var(--danger);
  }

  &.weak {
    width: 50%;
    //outline: 1px solid orange;
    background-color: #e87f0c;
  }

  &.medium {
    width: 80%;
    //outline: 1px solid yellow;
    background-color: #e2a700;
  }

  &.strong {
    width: 100%;
    //outline: 1px solid green;
    background-color: var(--positive);
  }
}

.label,
.hint {
  font-size: 12px;
}

.hint {
  color: var(--fgSofter);
  margin-top: 5px;
}
