.container {
  height: 100%;
  margin: auto;
  max-width: 720px;

  :global {
    .error {
      color: var(--danger);
    }

    .site,
    .name {
      display: flex;
      margin: 10px 0;
      align-items: center;

      .s {
        color: var(--fgMedium);
      }

      font-size: 20px;
      input {
        flex: 1;
        font-size: 20px;
      }
    }
    .confirm {
      text-align: right;
    }
  }
}
