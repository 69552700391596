.invites {
  display: block;
  flex-direction: column;

  :global {
    h4 {
      font-weight: bold;
      margin: 5px 0;
    }

    button {
      border: none;
      background: none;
      color: var(--link);
      margin: 0;
      padding: 0 10px 0 0;

      &:hover {
        color: var(--linkHover);
      }
    }

    .list {
      .item {
        margin: 5px 0;

        &.invite {
          border-radius: 4px;
          color: var(--fg);
          background-color: var(--dim3);
          padding: 10px 16px 10px 24px;
          margin-bottom: 10px;
        }

        .generated {
          color: var(--fgSofter);
          size: 14px;
          margin-bottom: 10px;
        }

        .code {
          margin: 10px 0;

          color: var(--fgSofter);
          a {
            color: var(--fgSofter);
          }

          &.regenerated a {
            @keyframes :global(highlight-regenerated) {
              0% {
                opacity: 0;
                color: white;
              }

              25% {
                opacity: 1;
              }

              50% {
                opacity: 0;
              }

              100% {
                opacity: 1;
                color: var(--fgSofter);
              }
            }
            animation: highlight-regenerated 2s ease-in-out 1;
          }
        }
      }

      .invited {
        flex-direction: row;

        a {
          display: inline-block;
          margin-right: 5px;
        }
      }
    }
  }

  .content {
    margin: 10px 0;
  }

  .createInvite {
    border-radius: 4px;
    padding: 10px 16px 0 24px;
    color: var(--fg);
    background-color: var(--dim3);
    > span {
      font-size: 24px;
    }
  }

  .highlighted {
    /*animate background color*/
    animation: highlight 1s ease-in-out infinite;

    @keyframes highlight {
      0% {
        background-color: var(--bg);
      }

      50% {
        background-color: var(--dim2);
      }

      100% {
        background-color: var(--bg);
      }
    }
  }
}
