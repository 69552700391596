#themePreview {
  margin-top: 64px;
  display: flex;
  justify-content: center;
  gap: 8px;

  .section {
    padding: 40px;
    border: solid 1px var(--fgAlmostInvisible);
    border-radius: 4px;
    position: relative;

    > .label {
      position: absolute;
      top: -8px;
      left: 32px;
      padding: 0 8px;
      font-size: 0.8em;
      background-color: var(--bg);
      color: var(--fgSoft);
    }
  }

  hr {
    border: none;
    border-bottom: solid 1px var(--fgGhost);
  }

  .box {
    color: var(--fg);
    width: 160px;
    border-radius: 8px;
    margin-bottom: 16px;
    overflow: hidden;
    > .label {
      padding: 20px;
      text-align: center;
    }
    > .dimDemo {
      display: flex;
      > div {
        color: var(--fgSoftest);
        flex: 1 0 auto;
        text-align: center;
        padding: 16px 0;
      }
    }
    &.normal {
      border: solid 1px var(--dim3);
    }
    &.elevated {
      box-shadow:
        0 0 1px var(--shadow),
        0 0 16px var(--shadow);
    }
    &.lowered {
      box-shadow:
        inset 0 0 1px var(--shadow),
        inset 0 0 4px var(--shadow);
    }
  }

  .row {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 4px 8px;
    border-radius: 4px;
    margin-bottom: 2px;
  }

  .sample {
    box-sizing: border-box;
    height: 16px;
    border-radius: 8px;
    width: 16px;
    border: solid 1px var(--fgAlmostInvisible);
  }

  $colors: fgHardest, fgHarder, fgHard, fg, fgMedium, fgSoft, fgSofter, fgSoftest, fgGhost, fgAlmostInvisible, bg,
    primary, primaryHover, primaryGhost, onAccent, danger, dangerHover, dangerGhost, positive, positiveHover,
    positiveGhost, link, linkVisited, linkHover, linkGhost;

  @each $c in $colors {
    .#{$c} {
      &.clr {
        color: var(--#{$c});
      }
      &.bgr {
        background-color: var(--#{$c});
      }
    }
  }

  .lowered {
    background-color: var(--lowered);
  }
  .lowered2 {
    background-color: var(--lowered2);
  }
  .elevated {
    background-color: var(--elevated);
  }
  .dim1 {
    background-color: var(--dim1);
  }
  .dim2 {
    background-color: var(--dim2);
  }
  .dim3 {
    background-color: var(--dim3);
  }

  //.shadow{ box-shadow: 0 0 10px var(--shadow); }
  //.glass{ background-color: var(--glass); }
}
