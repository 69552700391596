.modal {
  max-height: calc(100vh - 80px); // Maximum height is the screen height minus 20px
  overflow-y: auto; // Enable vertical scrolling if the content is larger than the container
  height: fit-content; // The height of the container will be as large as the content up to max-height
}

.container {
  h1,
  h2,
  h3,
  h4 {
    margin-block-end: 0;
    margin-block-start: 0;

    & > :global(.i):before {
      vertical-align: middle;
      font-size: 26px;
      padding-right: 4px;
    }
  }

  .shortTitle {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  input {
    flex: 1;
    border: none;
    padding: 4px 10px;

    display: flex;
    /*height: 40px;*/
    background: var(--input-bg);
    justify-content: center;
    align-items: stretch;
    border-radius: 4px;
    overflow: hidden;

    box-sizing: border-box;
    width: 100%;
    font-size: 18px;
    //margin-bottom: 16px;
  }

  .decodeInput {
    margin-top: 8px;
    width: 260px;
  }

  label {
    display: block;
    font-size: 18px;
    margin-bottom: 4px;
  }

  .decoded {
    overflow-y: auto; // Enable vertical scrolling if the content is larger than the container
  }

  button.cutButton {
    background: var(--dim2);
    color: var(--fgMedium);

    &:hover {
      background: var(--dim3);
    }

    .i:before {
      vertical-align: middle;
      font-size: 26px;
      padding-right: 4px;
    }
    span {
      margin-right: 4px;
    }
    font-size: 18px;
  }

  .columns {
    display: flex;
    flex-direction: row;
    gap: 10px;

    :last-child {
      flex-grow: 1; /* The right button takes up the remaining space */
    }
  }

  button.copyButton {
    color: var(--button-fg);
    background: var(--button-bg);
    width: 100%;
    display: flex;
    justify-content: center;

    &:hover {
      background: var(--button-hover-bg);
    }
  }

  .buttonSend {
    background: var(--primary);
  }

  .encodingResult {
    max-height: 200px;
    overflow-y: auto;
    word-break: break-all;
    color: var(--fgSofter);
  }

  .hint {
    display: block;
    color: var(--fgSofter);
    font-size: 14px;
    margin: 8px;

    :global(.i):before {
      vertical-align: middle;
      font-size: 16px;
      padding-right: 4px;
    }
  }
  .info {
    p {
      margin-top: 0;
    }
  }

  .collapsed {
    display: none;
  }

  form {
    margin-top: 8px;
  }

  .submit {
    padding-top: 16px;
  }

  .togglePass {
    color: var(--fgSoft);
    font-size: 20px;
    position: absolute;
    right: 8px;
    margin-top: -25px;
    margin-right: 14px;
    cursor: pointer;
  }
}
