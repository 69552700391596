.weakPasswordConfirmationContainer {
  padding-bottom: 16px;
  display: flex;
  gap: 8px;
  align-items: flex-start;
  > label {
    font-size: 14px;
    line-height: 1.2em;
  }
  > input[type='checkbox'] {
    display: inline-block;
    margin: 0;
    width: auto;
  }
}
