.container {
  width: 100%;
  bottom: 0;
  padding-top: 60px;
  padding-bottom: 60px;
}

.innerContainer {
  padding: 0 16px;
}

@media only screen and (min-width: 600px) {
  .innerContainer {
    padding: 0 102px;
  }
}
@media only screen and (min-width: 1180px) {
  .innerContainer {
    padding: 0 240px;
  }
}

.monster {
  position: fixed;
  left: 20px;
  bottom: 0;
  height: 50px;
  width: 50px;
}

.monsterPink {
  /*colorize svg*/
  fill: #ffc0cb;
}

.monsterNy {
  height: 60px;
  width: 50px;
}

@media only screen and (max-width: 600px) {
  .monster {
    display: none;
  }
}
.monster svg {
  height: 100%;
  width: 100%;
}
