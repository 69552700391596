:global {
  /* content wrapper for the embedded app card */
  .oauth-app {
    width: 280px;
    height: 280px;
  }
}

/* styles unique to the full app card */
.appModal {
  background: var(--bg);
  padding: 24px;

  max-width: 500px;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 16px;
}

/* displayed as true modal with overlay */
.appModal.fixed {
  z-index: 1000;
  position: fixed;

  width: 500px;
  max-height: calc(100vh - 68px);
  overflow-y: auto;

  border: 1px solid var(--inactive-fg);
  border-radius: 16px;

  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  @media screen and (max-width: 600px) {
    width: 90%;
  }
}

.appCard {
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;

  h2,
  h3,
  h4,
  p {
    margin: 0;
  }

  .logoContainer {
    width: 120px;
    height: 120px;

    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
  }

  .nameContainer {
    width: 100%;
    text-align: center;
    margin-bottom: 16px;

    .name {
      font-weight: 600;
      overflow-wrap: break-word;

      font-size: 1.25rem;
      max-width: 340px;
      margin: 0 auto;
      overflow: hidden;
      text-overflow: ellipsis;

      span {
        display: block;
        font-size: 0.875rem;
        color: var(--inactive-fg);
        margin-top: 4px;
      }
    }
  }

  .installationsCountContainer {
    display: flex;
    align-items: center;
    gap: 4px;
    color: var(--inactive-fg);
    font-size: 0.875rem;
    margin-top: auto;
  }

  .logoContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .descriptionContainer {
    word-break: break-word;
    flex: 1;
    width: 90%;

    a {
      display: inline-block;
    }
  }

  .buttonsContainer {
    display: flex;
    flex-direction: row; /* Default horizontal layout */
    align-items: center;
    justify-content: center;
    width: auto;
    gap: 16px; /* Larger gap for horizontal layout */
    flex-wrap: wrap; /* Allow wrapping if space is tight */

    /* Switch to vertical layout when container width is too narrow */
    @media (max-width: 400px) {
      flex-direction: column;
      gap: 8px; /* Smaller gap for vertical layout */
    }

    &.ownerButtons {
      flex-direction: row;
      gap: 16px;

      button {
        margin: 0;
        font-size: 0.875rem;
        color: var(--link);

        &.danger {
          color: var(--negative-fg);
        }
      }
    }
  }

  /* styles unique to the embedded app card */
  &.embedded {
    background-color: var(--appCard-bg);
    width: calc(100% - 48px);
    height: calc(100% - 48px);
    margin: 0;
    padding: 24px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: background-color 0.2s ease;

    &:hover {
      background-color: var(--appCard-hoverBg);
      cursor: pointer;
    }

    .nameContainer {
      .name {
        white-space: nowrap;
      }
    }
  }

  .consentDisclaimer {
    font-size: 0.875rem;
    color: var(--negative-fg);
  }
}

.scopeContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.author {
  display: inline-block;
}
