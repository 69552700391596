.controls_align {
  div {
    margin-right: 12px;
  }

  input {
    width: 100%;
    margin: 0;
    margin-bottom: 0;
  }
}

.profile_name {
  padding: 5px;
}

.edit_active {
  flex-grow: 1;
}
